<template>
  <div>
    <!-- MISSING: pic2B5D4F4CA4B8B590F740AFE8905C2FBF.jpg -->
    <img class="rounded img-large" src="">
    <p>
      Discoverers of an extraterrestrial pod, which landed on November 9 in a LEGOLAND® Park in Denmark, have announced that there are more on the way – the next due in a matter of days!
    </p>
    <p>
      "The map coordinates that we have posted on our website, www.bradfordrant.org show that the next pod will land somewhere in Florida; possibly in the Orlando area," said the scientists. "Now it seems we are able to estimate the time of arrival! We predict the pod will land sometime in the first few days of December."
    </p>
    <p>
      LEGO fans are advised to keep an eye on the scientists' website to find out the exact location of the pod's arrival which they will announce as soon as it lands on Earth.
    </p>
    <p>
      They have also posted a list of what to do if you find a pod:
    </p>
    <p>
      - Once we decipher the last set of map coordinates we will inform people at the arrival location to secure the pod.
    </p>
    <p>
      - If you arrive at a pod location, look for the contact person.
    </p>
    <p>
      - When you find the contact person, tell them the number of the pod you seek, for example, 'Pod No.2'. Be sure to write down the Pod ID.
    </p>
    <p>
      - The first person to arrive at the pod location and tell the contact the correct pod number will receive the pod.
    </p>
    <p>
      - It is important for others to make their way to the pod location and record the Pod ID so that it can be entered into the website to reveal the pod data.
    </p>
    <p>
      - If you can, take photos or video footage of the pod and send it to us at the Bradford Rant website.
    </p>
    <p>
      For more information, visit the scientists' website at <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>
    </p>
  </div>
</template>
